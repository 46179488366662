import { Container } from "@mui/material";
import React from "react";



export function LandingPage() {
  return (
    <div
    style={{
       
    }}
    >
      <Container>
        <h1>Content is coming soon</h1>
      </Container>
    </div>
  );
}